.prose {
  @apply text-gray-700 dark:text-gray-400;
}

.prose blockquote {
  @apply font-normal border-l-2 border-gray-200 dark:border-gray-800 dark:text-gray-400;
}

.prose figure {
  @apply md:-mx-6;
}

.prose img {
  @apply w-full rounded-md;
}

.prose a {
  @apply font-normal text-blue-600 no-underline break-words hover:underline dark:text-blue-500;
}

.prose a code {
  @apply text-blue-600 dark:text-blue-500;
}

.prose table {
  @apply my-2 border border-gray-200 rounded-md text-gray-1000 dark:border-gray-800 dark:text-gray-50;
}

.prose.comment table {
  @apply my-2;
}

.prose thead {
  @apply text-gray-800 dark:text-gray-200;
}

.prose thead th {
  @apply p-2;
  padding-left: 1rem !important;
}

.prose thead th,
.prose tbody th,
.prose tbody td {
  @apply p-2 border-r border-gray-200 dark:border-gray-800;
  padding-left: 1rem !important;
}

.prose tbody tr {
  @apply border-b border-gray-200 dark:border-gray-800;
}

.prose thead tr {
  @apply border-b-2 border-gray-200 dark:border-gray-800;
}

.prose tbody tr:nth-child(2n) {
  @apply bg-gray-50 dark:bg-gray-900;
}

.prose b,
.prose strong {
  @apply font-bold text-gray-900 dark:text-gray-300;
}

.prose hr {
  @apply border-gray-200 dark:border-gray-800;
}

/* the kg-embed-card gets applied to iframes sent from Ghost. this preserves a good video aspect ratio */
.prose .kg-embed-card {
  @apply my-4;
  position: relative;
  padding-top: 56.25%;
}

.prose .kg-embed-card iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6 {
  @apply font-sans font-bold text-gray-900 dark:text-gray-100;
  scroll-margin-top: 4rem;
}

.prose h1 a,
.prose h2 a,
.prose h3 a,
.prose h4 a,
.prose h5 a,
.prose h6 a {
  @apply font-sans font-bold no-underline text-gray-1000 dark:text-gray-100;
}

.prose h5 {
  @apply mt-12 -mb-3 text-xl;
}

.prose pre {
  @apply px-8 py-6 space-y-4 text-sm text-gray-800 bg-gray-400 border-gray-300 rounded-md bg-opacity-5 dark:border-gray-800 dark:text-gray-200;
}

.prose.comment {
  @apply max-w-none;
}

.prose.comment pre {
  @apply px-3 py-2 my-2;
}

/* this is needed to avoid HN comments from overflowing off the right edge of the screen */
.prose p {
  @apply break-words;
  word-break: break-word;
}

.prose.comment p,
.prose.comment ul,
.prose.comment ol {
  @apply my-3;
}

.prose.comment p:first-of-type {
  margin-top: 0 !important;
}

.prose.comment p + p {
  margin-top: 0;
}

.prose.comment pre {
  @apply my-3;
}

.prose.comment a {
  @apply break-all;
}

.prose p + p {
  margin-top: 0;
}

.prose code {
  @apply rounded-md border-gray-100 bg-gray-100 px-1 py-0.5 text-gray-800 dark:border-gray-800 dark:bg-gray-900 dark:text-gray-200;
}

.prose code,
.prose pre {
  @apply text-sm font-medium whitespace-pre-wrap;
  word-break: break-word;
}

.prose pre > code {
  @apply text-gray-200 bg-gray-800;
}

.prose pre {
  @apply text-gray-100 bg-gray-800;
}

.prose sup {
  @apply text-sm text-blue-500 dark:text-gray-400;
  vertical-align: super;
  top: 0px;
  position: relative;
  scroll-margin-top: 4rem;
}
