@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply max-h-screen overflow-hidden text-base antialiased;
  }
}

@layer utilities {
  .text-primary {
    @apply text-gray-900 dark:text-gray-100;
  }

  .text-secondary {
    @apply text-gray-700 dark:text-gray-300;
  }

  .text-tertiary {
    @apply text-gray-500 dark:text-gray-400;
  }

  .text-quaternary {
    @apply text-gray-400 dark:text-gray-500;
  }

  .bg-elevated {
    @apply bg-gray-400 bg-opacity-5 dark:bg-gray-50;
  }

  .filter-none {
    filter: none;
  }

  .filter-grayscale {
    filter: grayscale(100%);
  }

  .filter-saturate {
    -webkit-filter: brightness(105%) saturate(200%) contrast(1);
    filter: brightness(105%) saturate(200%) contrast(1);
  }

  .filter-blur {
    backdrop-filter: saturate(180%) blur(20px);
  }

  .highlight-link-hover {
    @apply -mx-0.5 rounded-sm bg-opacity-20 px-0.5 text-gray-900 no-underline dark:bg-opacity-100 dark:text-gray-300 md:hover:bg-yellow-400 md:hover:bg-opacity-30 md:dark:hover:bg-yellow-500 md:dark:hover:bg-opacity-100 md:dark:hover:text-gray-900;
  }

  .highlight-link {
    @apply -mx-0.5 bg-opacity-20 px-0.5 text-gray-900 dark:bg-gray-100 dark:bg-opacity-20 dark:text-gray-300 md:hover:bg-opacity-30 md:dark:hover:bg-yellow-500 md:dark:hover:bg-opacity-100 md:dark:hover:text-gray-900;
  }

  .blink {
    animation: blink-animation 1.5s steps(2, start) infinite;
    -webkit-animation: blink-animation 1.5s steps(2, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .ripple {
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    margin-left: -32px;
    margin-top: -32px;
    animation: ripple 2s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @keyframes ripple {
    0% {
      opacity: 0.8;
      transform: scale(0);
    }

    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  .meta-wrap {
    background: transparent;
    animation: 48s spin ease-in-out infinite;
    -webkit-filter: brightness(105%) saturate(200%) contrast(1);
    filter: brightness(105%) saturate(200%) contrast(1);
    border-radius: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: opacity 0.3s ease-in-out;
  }

  .meta:before,
  .meta:after {
    content: '';
    position: absolute;
    width: 1024px;
    height: 1024px;
    left: 50%;
    top: 50%;
    margin: -50px;
    background: linear-gradient(#ff9900, #ff007a);
    border-radius: 50%;
    -webkit-filter: blur(60px);
    filter: blur(60px);
    animation: move 100s infinite alternate ease-in-out;
  }

  .meta.small:before,
  .meta.small:after {
    width: 196px;
    height: 196px;
  }

  .meta:before {
    animation-duration: 29.3s;
    animation-delay: -3.3s;
  }

  .meta:nth-child(2):before {
    animation-duration: 34.7s;
    animation-delay: -2.7s;
  }

  .meta:nth-child(2):after {
    animation-duration: 28.7s;
    animation-delay: -5.32s;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes move {
    0% {
      transform: translate(0%, 2%);
    }
    15% {
      transform: translate(-20%, 20%);
    }
    30% {
      transform: translate(30%, -30%);
    }
    40% {
      transform: translate(-20%, 20%);
    }
    60% {
      transform: translate(20%, -20%);
    }
    80% {
      transform: translate(-20%, -10%);
    }
    100% {
      transform: translate(20%, 10%);
    }
  }
}
